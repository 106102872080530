.archive {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
}

.title {
  display: flex;
  flex-direction: row;
  gap: var(--space-s);
}
