.mainWrapper {
  width: 80%;
  height: 100%;
  position: relative;
}

.contentWrapper {
  width: 100%;
  height: 100%;
}

.contentWrapper > div > div > div {
  background-color: var(--foreground) !important;
  box-shadow: none !important;
  border: 0 !important;
}

.contentWrapper > div > div > div > div {
  border-color: rgba(0, 0, 0, 0) var(--background) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) !important;
}

.contentWrapper > div > div > div > div + div {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) var(--background) !important;
}

/* ||||||||||||||| MEDIA QUERIES ||||||||||||||| */

@media screen and (max-width: 640px) {
  .mainWrapper {
    width: 100%;
  }
}
