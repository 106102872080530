.mainFooter {
  padding-bottom: var(--space-xl);
}

div.footerFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--space-xl);
}

.talk {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icons {
  display: flex;
  gap: var(--space-m);
}

.timestamp {
  display: flex;
  text-align: right;
  flex-direction: column;
  gap: var(--space-s);
}

.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

/* ||||||||||||||| MEDIA QUERIES ||||||||||||||| */
@media only screen and (max-width: 640px) {
  .footerFlex {
    gap: var(--space-l);
  }
}
