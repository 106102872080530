.projectFooter {
  padding-bottom: var(--space-xxl);
}

/* ||||||||||||||| MEDIA QUERIES ||||||||||||||| */
@media only screen and (max-width: 640px) {
  .projectFooter {
    padding-bottom: var(--space-xl);
  }
}
