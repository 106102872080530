.latest {
  padding: 0 0 var(--space-xxl);
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
}

.featured {
  width: 100%;
}
.featuredFigure {
  position: relative;
}

.imgFeatured {
  width: 100%;
  height: auto;
}

.featuredCaption {
  display: flex;
  gap: var(--space-l);
  margin-top: var(--space-m);
  align-items: center;
}

.featuredFigure:hover > figcaption > .projectLink {
  width: 6.8rem;
}

.featuredFigure:hover > figcaption > .projectLink > .linkText {
  opacity: 1;
}

.featuredFigure:hover > figcaption > .projectLink > .linkIcon {
  transform: rotate(-90deg);
}

video {
  width: 100%;
  border-radius: var(--border-rounded-image);
}

/* ||||||||||||||| MEDIA QUERIES ||||||||||||||| */
@media only screen and (max-width: 640px) {
  article.featured {
    padding-bottom: var(--space-l);
  }
}
