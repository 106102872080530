/* ||||||||||||||| RESET STARTS ||||||||||||||| */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-touch-callout: none;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* ||||||||||||||| RESET ENDS ||||||||||||||| */
@font-face {
  font-family: 'PPNeueMontreal';
  src: url('assets/fonts/PPNeueMontreal-Book.otf');
  font-weight: normal;
  font-style: normal;
}
/* ||||||||||||||| ROOT STARTS ||||||||||||||| */
:root {
  --space-4: 0.25rem;
  --space-8: 0.5rem;
  --space-16: 1rem;
  --space-32: 2rem;
  --space-64: 4rem;
  --space-128: 8rem;

  --space-xs: var(--space-4);
  --space-s: var(--space-8);
  --space-m: var(--space-16);
  --space-l: var(--space-32);
  --space-xl: var(--space-64);
  --space-xxl: var(--space-128);

  --font-book: 'PPNeueMontreal', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif;

  --font-size-12: 0.75rem;
  --font-size-16: 1rem;
  --font-size-18: 1.125rem;
  --font-size-24: 1.5rem;
  --font-size-36: 2.25rem;
  --font-size-40: 2.5rem;
  --font-size-48: 3rem;
  --font-size-96: 6rem;

  --font-size-caption: var(--font-size-12);
  --font-size-body: var(--font-size-16);
  --font-size-highlight: var(--font-size-24);
  --font-size-sectionHeading: var(--font-size-48);
  --font-size-pageHeading: var(--font-size-96);

  --line-height-body: 1.5;
  --line-height-heading: 1.2;

  --color-dark: #131313;
  --color-off-white: #fafafa;
  --dimmed: 0.6;

  --background: var(--color-dark);
  --foreground: var(--color-off-white);

  --border-rounded-image: 0.375rem;

  --dimmed: 0.5;
  --image-fade: 0.95;
}
/* ||||||||||||||| ROOT ENDS ||||||||||||||| */
body {
  font-family: var(--font-book);
  -webkit-font-smoothing: antialiased;
  height: 100%;
  background-color: var(--background);
  color: var(--foreground);
}

img {
  opacity: var(--image-fade);
  border-radius: var(--border-rounded-image);
}

.autoHeight {
  height: 100%;
}

.grid {
  padding: 0 var(--space-xl);
}

a {
  color: var(--foreground);
  text-decoration: none;
  transition: all 0.2s;
}

a.out:hover::after {
  font-size: inherit;
  content: '↗';
  color: var(--foreground);
  margin-left: var(--space-s);
}

.in {
  cursor: pointer;
  transition: opacity 0.6s;
}

.in:hover {
  opacity: 0.75;
}

.max-wrapper {
  max-width: 1280px;
  margin: 0 auto;
}

#cursor {
  position: fixed;
  z-index: 9999999;
  pointer-events: none;
  transform: translate(-50%, -50%);
}

/* ||||||||||||||| HOME ||||||||||||||| */

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: auto/span 12;
  padding: var(--space-m) 0 var(--space-l) 0;
  margin-bottom: var(--space-xl);
}

button.ColorMode {
  padding: var(--space-s);
  color: var(--foreground);
  background: none;
  border: none;
  outline: none;
  font-size: var(--font-size-highlight);
  cursor: pointer;
  fill: var(--foreground);
}

button.ColorMode:hover > svg clipPath rect {
  width: 1.5rem;
}
svg clipPath rect {
  transition: width 0.4s ease-in-out;
}

section.intro {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-xxl);
  width: 70vw;
}

.introTxt {
  font-size: calc(36px + 2vw);
  line-height: var(--line-height-heading);
}

.homeImg {
  width: 20%;
  margin-bottom: var(--space-xxl);
  transition: all 0.2s ease-in-out;
}

.profileImg {
  display:flex;
  justify-content: flex-end;
}

/* ||||||||||||||| FUN + WORK ||||||||||||||| */

section.prelude {
  margin-bottom: var(--space-xxl);
  width: 45vw;
}

.indent {
  text-indent: var(--space-xl);
}

.layoutContainer {
  display: inline-block;
  vertical-align: bottom;
  font-size: 0;
  margin-left: -1rem;
  padding-bottom: var(--space-xxl);
}

.layoutImg {
  width: 100%;
  flex-shrink: 0;
  min-width: 100%;
  border-radius: var(--border-rounded-image);
}

.layoutFigure {
  position: relative;
  transition: all 0.2s ease-in-out;
  padding-left: var(--space-m);
}

.projectLink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--space-m);
  width: 10px;
  height: 24px;
  top: 0;
  right: 0;
  overflow: hidden;
  position: absolute;
  bottom: var(--space-m);
  padding: var(--space-s) var(--space-m);
  background: var(--background);
  border-radius: 999px;
  margin: var(--space-m);
  white-space: nowrap;
  transition: width 0.5s ease-in-out;
  transition-delay: 0.25s;
}

.linkIcon,
.linkIconOut {
  transition: all 0.3s ease-in-out;
  transition-delay: 0.25s;
}

.linkText {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.25s;
}

.layoutFigure:hover > figcaption > .projectLink {
  width: 6.8rem;
}

.layoutFigure:hover > figcaption > .projectLink > .linkText {
  opacity: 1;
}

.layoutFigure:hover > figcaption > .projectLink > .linkIcon {
  transform: rotate(-90deg);
}

.layoutFigure:hover > figcaption > .projectLink > .linkIconOut {
  transform: rotate(-45deg);
}

.caption {
  display: flex;
  flex-direction: row;
  gap: var(--space-l);
  align-items: center;
  margin-top: var(--space-m);
}

.tags {
  display: flex;
  flex-direction: row;
  gap: var(--space-m);
  opacity: var(--dimmed);
}

.item {
  display: inline-block;
  margin-bottom: var(--space-xl);
}
.oneThird {
  width: 33.33333%;
}
.twoThirds {
  width: 66.66667%;
}
.half {
  width: 50%;
}
.full {
  width: 100%;
}

/* ||||||||||||||| PROJECTS ||||||||||||||| */

section.content {
  display: flex;
  flex-direction: column;
  gap: var(--space-xxl);
  padding-bottom: var(--space-xxl);
}

article.projectIntro > div {
  display: flex;
  gap: var(--space-m);
  flex-direction: column;
}

article.projectIntro,
div.projectSection {
  width: 50vw;
  display: flex;
  flex-direction: column;
}

article.projectIntro {
  gap: var(--space-l);
}

div.projectSection {
  gap: var(--space-m);
}

.embedded {
  margin: var(--space-l) 0;
}

article.projectBlock {
  display: flex;
  flex-direction: column;
  gap: var(--space-xl);
}

div.projectImages {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
}

div.projectImages > figure > img {
  width: 80%;
}

div.projectImages > figure > figcaption {
  width: 80%;
  padding-top: var(--space-s);
  opacity: var(--dimmed);
}

/* ||||||||||||||| MEDIA QUERIES ||||||||||||||| */

@media screen and (max-width: 640px) {
  .grid {
    padding: 0 var(--space-m) var(--space-m);
  }

  header {
    margin-bottom: var(--space-l);
  }
  
  .homeImg {
    width: 60%;
  }

  article.projectIntro,
  div.projectSection,
  section.prelude,
  section.intro {
    width: 100%;
  }

  section.prelude,
  section.intro {
    margin-bottom: var(--space-xl);
  }

  div.projectImages > figure > img,
  div.projectImages > figure > figcaption {
    width: 100%;
  }

  .oneThird,
  .twoThirds,
  .half {
    width: 100%;
  }
}
