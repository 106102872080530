#navItem {
  padding: var(--space-m) 0;
  transition: opacity 0.6s;
  transition-delay: 0.2s;
  width: fit-content;
}

.active {
  font-size: var(--font-size-pageHeading) !important;
  line-height: var(--line-height-heading);
  text-transform: uppercase;
}

#navItem:hover {
  opacity: var(--dimmed);
}

/* ||||||||||||||| MEDIA QUERIES ||||||||||||||| */
@media only screen and (max-width: 640px) {
  .active {
    font-size: var(--font-size-36) !important;
  }
}
